import NotificationToast from "../lib/NotificationToast";
import {customAlert, CustomConfirm, CustomConfirmLogout} from "../lib/CustomConfirm";
import autoComplete from "@tarekraafat/autocomplete.js";
import formControl from "../lib/FormControl";

import {SelectInput, SelectInputTop} from "../Components/SelectInput";
import {DatePicker, DatePickerTwo, TimePicker} from "../Components/DatePicker";
import {calendar} from "../Components/calendar";
import {InputNumeric} from "../Components/InputNumeric";
import {TextEditor} from "../Components/TextEditor";
import tinymce from "tinymce";
import tippy from "tippy.js";
import Chart from 'chart.js/auto';
import autocolors from 'chartjs-plugin-autocolors';

Turbolinks.start()

//Custom Select Input
customElements.define("custom-select", SelectInput, {extends: "select"})
customElements.define("custom-select-top", SelectInputTop, {extends: "select"})
customElements.define("time-picker", TimePicker, {extends: "input"})
customElements.define("date-picker", DatePicker, {extends: "input"})
customElements.define("date-picker-two", DatePickerTwo, {extends: "input"})
customElements.define("number-format", InputNumeric, {extends: "input"})
customElements.define("text-editor", TextEditor, {extends: "textarea"})
customElements.define("calendar-form", calendar, {extends: "div"})

function getAllFormSubit() {
    let allForm = document.querySelectorAll(".modal__body form")
    allForm.forEach((item) => {
        item.addEventListener("submit", (e) => {
            e.preventDefault()
            document.querySelector(".modal__box.active .modal__footer .modalForm__submit").click()
        })
    })
}

document.addEventListener("turbolinks:load", function () {

    let notifyMSG = notify
    if (notifyMSG !== null) {
        NotificationToast(notifyMSG.type, notifyMSG.message)
    }

    let tooltip = document.querySelectorAll("[data-tooltip]")
    tooltip.forEach((item) => {
        if (item.dataset.tooltip !== "<ul></ul>" && item.dataset.tooltip !== "<ol></ol>") {
            let tooltipElement = tippy(item, {
                content: item.dataset.tooltip,
                allowHTML: true,
                animation: "shift-away",
                placement: "bottom",
                theme: "translucent",
                interactive: false
            })
        }
    })

    let calcScrollValue = () => {
        let scrollProgress = document.getElementById("progress");
        let progressValue = document.getElementById("progress-value");
        let pos = document.documentElement.scrollTop;
        let calcHeight =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight;
        let scrollValue = Math.round((pos * 100) / calcHeight);
        if (pos > 100) {
            scrollProgress.style.display = "grid";
        } else {
            scrollProgress.style.display = "none";
        }
        scrollProgress.addEventListener("click", () => {
            document.documentElement.scrollTop = 0;
        });
        scrollProgress.style.background = `conic-gradient(#104e94 ${scrollValue}%, #ffffff2e ${scrollValue}%)`;
    };
    let progresseStatus = document.getElementById("progress");
    if (progresseStatus !== null && progresseStatus !== undefined) {
        window.onscroll = calcScrollValue;
        window.onload = calcScrollValue;
    }

// Autoclose dropdown
    window.addEventListener("click", (e) => {
        if (!e.target.matches('.icon.fi-rr-menu-dots-vertical') && !e.target.matches('.table_drop_action')) {
            document.querySelectorAll('.table_drop_action').forEach(elm => {
                if (elm.classList.contains("active")) {
                    elm.classList.remove("active");
                }
            });
        }

        if (document.querySelector(".dropdown .default_option") !== null) {
            if (!e.target.matches('.dropdown .default_option')) {
                if (document.querySelector(".dropdown .default_option + ul").classList.contains("active")) {
                    document.querySelector(".dropdown .default_option + ul").classList.remove("active");
                }
            }
        }

        if (!e.target.matches('.default_option')) {
            document.querySelectorAll('.dropdownList > ul').forEach(elm => {
                if (elm.classList.contains("active")) {
                    elm.classList.remove("active");
                }
            });
        }
    });

// Gestion de la suppression
    let formDelete = document.querySelectorAll(".deleteForm");
    formDelete.forEach((item) => {
        item.addEventListener("submit", (e) => {
            e.preventDefault();
            CustomConfirm(item)
        })
    })

// Table DropDown Action menu
    let table_drop_action = document.querySelectorAll('.table_drop_action');
    table_drop_action.forEach(element => {
        element.addEventListener("click", () => {
            table_drop_action.forEach(elm => {
                if (elm.classList.contains("active") && elm !== element) {
                    elm.classList.remove("active");
                }
            });
            element.classList.toggle("active");
        });
    });


    // Gestion de l'exportation vers excel
    let excel_export = document.querySelectorAll(".excel__export");
    excel_export.forEach((item) => {
        item.addEventListener("click", (e) => {
            e.preventDefault();
            let exportAnimation = document.querySelector(".excel_export_popUp")
            exportAnimation.classList.add("active")

            let url = item.href;
            let docName = (item.dataset.name).trim()

            fetch(url).then(res => res.blob()).then(file => {
                let tempUrl = URL.createObjectURL(file);
                const aTag = document.createElement("a");
                aTag.href = tempUrl;
                let date = new Date();
                aTag.download = docName + '.xlsx';
                document.body.appendChild(aTag);
                aTag.click();
                URL.revokeObjectURL(tempUrl);
                aTag.remove();
                exportAnimation.classList.remove("active");
                NotificationToast("success", "Exportation vers <b>Excel</b> reussi");
            }).catch(() => {
                exportAnimation.classList.remove("active");
                NotificationToast("error", "Erreur lors de l'exportation...");
            });

        })
    })

// Navigation sidebar toggle

    let sidebar = document.querySelector(".sidebar");
    let sidebarBtn = document.querySelector("#close-nav");
    sidebarBtn.addEventListener("click", () => {
        sidebar.classList.toggle("close")
        menuBtnChange()
    });

// Navigation sidebar control
    let nav_dropdown_link = document.querySelectorAll(".dropdown-link");
    for (let i = 0; i < nav_dropdown_link.length; i++) {
        nav_dropdown_link[i].addEventListener("click", (e) => {
            let nav_dropdown_linkParent = nav_dropdown_link[i].parentElement.parentElement;
            nav_dropdown_linkParent.classList.toggle("showMenu")
        });
    }

    // Filter Drop
    let filterDrop = document.querySelector(".dropdown .default_option");
    if (filterDrop !== null && filterDrop !== undefined) {
        filterDrop.addEventListener("click", () => {
            let filterElm = document.querySelector(".dropdown .default_option + ul");
            filterElm.classList.toggle("active");
        });
    }

    let dropdownList = document.querySelectorAll(".dropdownList .default_option");
    dropdownList.forEach((dropdown) => {
        let dropdownListElm = dropdown.parentElement.querySelector("ul");
        if (dropdownList !== null && dropdownList !== undefined) {
            dropdown.addEventListener("click", () => {
                dropdownListElm.classList.toggle("active");
            });
        }
    })

    let groupAction_caller = document.querySelector(".groupAction_caller")
    if (groupAction_caller !== null) {
        groupAction_caller.addEventListener("click", () => {
            let allCheck = document.querySelectorAll("input.group_action_check:checked")
            let ids = "";
            allCheck.forEach((item) => {
                ids += item.value + ","
            })
            if (ids !== "") {
                ids = ids.slice(0, -1)
            }
            if (groupAction_caller.parentElement.nodeName == 'FORM') {
                let id = groupAction_caller.parentElement.querySelector("input[name='id']")
                if (ids !== "") {
                    id.value = ids
                } else {
                    id.value = ""
                }
            } else {
                let link = groupAction_caller.parentElement.querySelectorAll("a")
                link.forEach((item) => {
                    const urlObj = new URL(item.href);
                    urlObj.search = '';
                    if (ids !== "") {
                        item.href = urlObj.toString() + "?key=" + ids
                    } else {
                        item.href = urlObj.toString()
                    }

                })
            }
        });
    }


    function menuBtnChange() {
        if (!sidebar.classList.contains("close")) {
            sidebarBtn.classList.replace("fi-rr-menu-burger", "fi-rr-align-right")
        } else {
            sidebarBtn.classList.replace("fi-rr-align-right", "fi-rr-menu-burger")
        }
    }

// Gestion de bar de recherche
    let searchField = document.querySelector(".search_field .input")
    if (searchField !== null && searchField !== undefined) {
        const autoCompleteJS = new autoComplete({
            selector: "#autoComplete",
            placeHolder: "Rechercher...",
            data: {
                src: searchList,
                cache: true,
            },
            resultsList: {
                element: (list, data) => {
                    if (!data.results.length) {
                        // Create "No Results" message element
                        const message = document.createElement("div");
                        // Add class to the created element
                        message.setAttribute("class", "no_result");
                        // Add message text content
                        message.innerHTML = `<span>Aucun résultat trouvé pour "${data.query}"</span>`;
                        // Append message element to the results list
                        list.prepend(message);
                    }
                },
                noResults: true,
                maxResults: 15,
                tabSelect: true,
            },
            // submit form
            submit: true,
            resultItem: {
                highlight: true
            },
            events: {
                input: {
                    selection: (event) => {
                        const selection = event.detail.selection.value;
                        let url = window.location.origin + window.location.pathname
                        const params = new URLSearchParams({
                            q: selection
                        });
                        Turbolinks.visit(url + '?' + params.toString());
                    }
                }
            }
        });
    }


// Gestion boutton tabs de navigation

    // Recuperation du current tabs
    let tabs_id = sessionStorage.getItem("tabs__id")

    if (tabs_id !== null && tabs_id !== undefined) {
        let oldTab = document.querySelector('.tabs__radio:checked')
        let newTabs = document.querySelector('#' + tabs_id)
        if (oldTab !== null && oldTab.id !== tabs_id) {
            newTabs.checked = true
            oldTab.checked = false
        }
    }

    let tabsBtn = document.querySelectorAll('.tabs__radio')
    tabsBtn.forEach(element => {
        element.addEventListener("click", () => {
            sessionStorage.setItem("tabs__id", element.id)
        });
    });

    let JS_Call_Url_Get_Form = document.querySelectorAll(".JS_Call_Url_Get_Form")
    JS_Call_Url_Get_Form.forEach((item) => {
        item.addEventListener('click', (e) => {
            e.preventDefault()
            let url = item.dataset.url
            if (url === undefined) {
                url = item.href
            }

            if (url !== null && url !== undefined) {
                fetch(url, {
                    method: "POST",
                    body: null,
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                })
                    .then(res => res)
                    .then(response => {
                        if (response.status === 200) {
                            return response.json()
                        } else {
                            NotificationToast("error", "Erreur lors de l'opération.")
                        }
                    })
                    .then(data => {
                        let generateEditForm = document.querySelector("#JS_GenerateForm")
                        if (data === undefined || generateEditForm === null) {
                            return 0
                        }
                        generateEditForm.innerHTML = data.message

                        let form = generateEditForm.querySelector("form")
                        if (form != null) {
                            form.setAttribute("method", "post")
                        }

                        let requiredText = generateEditForm.querySelector("[data-control ~= 'noEmpty']")
                        if (requiredText !== null) {
                            if (generateEditForm.querySelector(".required__placeholder") == null) {

                                let paragraph = document.createElement('p')
                                paragraph.classList.add('required__placeholder')
                                paragraph.innerHTML = `<i class="fi-rr-exclamation"></i> champs obligatoires`

                                generateEditForm.querySelector(".modal__container").insertBefore(paragraph, generateEditForm.querySelector(".modal__footer"))
                            }
                        }

                        // Toggle Switch Edit Modal
                        toggleEditForm()

                        setTimeout(function () {
                            generateEditForm.querySelector('.modal__box').classList.add('active')
                            document.querySelector("body").style.overflow = "hidden"
                        }, 100)

                        // Close Modal
                        let close__modal = generateEditForm.querySelectorAll(".close__modal")
                        if (close__modal !== null && close__modal !== undefined) {
                            close__modal.forEach((item) => {
                                item.addEventListener("click", () => {
                                    CloseModal()
                                })
                            })
                        }

                        let buttonGroupTabs = generateEditForm.querySelectorAll('.button-group-item')
                        if (buttonGroupTabs !== null && buttonGroupTabs !== undefined) {
                            buttonGroupTabs.forEach(element => {
                                element.addEventListener("click", () => {
                                    element.parentNode.querySelectorAll('.button-group-item').forEach(elm => {
                                        if (elm.classList.contains("active") && elm !== element) {
                                            elm.classList.remove("active");
                                        }
                                    });
                                    let buttonGroupContent = element.parentNode.parentNode
                                    let oldButtonGroupContent = buttonGroupContent.querySelector(".button-group-content-item.active")
                                    if (oldButtonGroupContent !== null && oldButtonGroupContent !== undefined) {
                                        oldButtonGroupContent.classList.remove('active')
                                    }
                                    element.classList.add("active");
                                    buttonGroupContent.querySelector('#' + element.dataset.id)?.classList.add('active')
                                });
                            });
                        }

                        // Submit Form
                        let modalForm__submit = generateEditForm.querySelector(".modalForm__submit")
                        if (modalForm__submit !== null && modalForm__submit !== undefined) {
                            modalForm__submit.addEventListener("click", () => {
                                submitForm(modalForm__submit)
                            })

                            let form = generateEditForm.querySelector("form")
                            form.addEventListener("submit", (e) => {
                                e.preventDefault()
                                submitForm(modalForm__submit)
                            })
                        }

                        // Load all input file preview Img
                        loadInputFile()

                        //JS_GetTargetData
                        let JS_GetTargetData = generateEditForm.querySelectorAll(".JS_GetTargetData")
                        JS_GetTargetData.forEach((item) => {
                            item.addEventListener("click", (e) => {
                                e.preventDefault()
                                let url = item.dataset.url
                                let target = item.dataset.target

                                if (url !== null && url !== undefined) {
                                    fetch(url, {
                                        method: "POST",
                                        body: null,
                                        headers: {
                                            'X-Requested-With': 'XMLHttpRequest'
                                        }
                                    })
                                        .then(res => res)
                                        .then(response => {
                                            if (response.status === 200) {
                                                return response.json()
                                            } else {
                                                NotificationToast("error", "Erreur lors de l'opération.")
                                            }
                                        })
                                        .then(data => {
                                            if (data === undefined || target === null) {
                                                return 0
                                            }

                                            let div = document.createElement("div")
                                            div.classList.add("target_" + new Date().getTime())
                                            div.innerHTML = data.message
                                            document.querySelector("#" + target).appendChild(div)

                                            let JS_DeleteTargetData = generateEditForm.querySelectorAll(".JS_DeleteTargetData")
                                            JS_DeleteTargetData.forEach((item) => {
                                                item.addEventListener("click", (e) => {
                                                    e.preventDefault()
                                                    let parent = item.parentElement
                                                    parent.remove()
                                                })
                                            })
                                        })
                                }
                            })
                        })

                        // JS_DeleteTargetData
                        let JS_DeleteTargetData = generateEditForm.querySelectorAll(".JS_DeleteTargetData")
                        JS_DeleteTargetData.forEach((item) => {
                            item.addEventListener("click", (e) => {
                                e.preventDefault()
                                let parent = item.parentElement
                                parent.remove()
                            })
                        })
                    })
                    .catch(err => {
                        NotificationToast("error", "Erreur XHR: " + err)
                    });

                return true
            }
        })
    })

    // All Form submit in Ajax Promise
    let allFormElement = document.querySelectorAll("form.custom__form")
    allFormElement.forEach((item) => {
        item.addEventListener("submit", (e) => {
            e.preventDefault()
            let formElement = item
            let payload = new FormData(formElement)
            let url = formElement.action
            let formControlResult = formControl(formElement, payload)
            if (formControlResult === true) {
                fetch(url, {
                    method: "POST",
                    body: payload,
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                })
                    .then(res => res.json())
                    .then(data => {
                        Turbolinks.visit(data?.redirect + window.location.search)
                        document.addEventListener("turbolinks:load", function () {
                            if (data !== undefined) {
                                NotificationToast(data.type, data.message)
                                setTimeout(() => { //remove data after 500ms
                                    data = undefined;
                                }, 500);
                            }
                        })
                    })
                    .catch(err => {
                        NotificationToast("error", "Erreur XHR: " + err)
                    });
                return true
            }
        })
    })

    let custom_link = document.querySelectorAll(".custom-link")
    custom_link.forEach((item) => {
        item.addEventListener("click", () => {
            let url = item.dataset.url
            Turbolinks.visit(url)
        })
    })

    let checkboxAll = document.querySelector(".checkboxAll")
    if (checkboxAll !== null) {
        checkboxAll.addEventListener('click', (e) => {
            let allCheck = document.querySelectorAll('.checkboxItem')
            allCheck.forEach((item) => {
                item.click()
            })
        })
    }

    let JSlogout__user = document.querySelector("#JSlogout__user")
    JSlogout__user?.addEventListener("click", (e) => {
        e.preventDefault()
        CustomConfirmLogout(JSlogout__user.dataset.url)
    })


    const chart__stats_payements = document.querySelector("#chart__stats_payements")
    if (chart__stats_payements !== null) {
        const data = dataStats
        let datasets = [];
        Object.keys(data).forEach(function (key, index) {
            datasets.push(
                {
                    label: key,
                    data: Object.values(data[key]),
                    pointStyle: 'circle',
                    pointRadius: 5,
                    pointHoverRadius: 8,
                    tension: 0.2
                }
            )
        });

        new Chart(
            chart__stats_payements,
            {
                type: 'line',
                data: {
                    labels: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
                    datasets: datasets
                },
                options: {
                    plugins: {
                        autocolors,
                        legend: {
                            labels: {
                                // This more specific font property overrides the global property
                                font: {
                                    family: 'poppins',
                                    weight: '500'
                                }
                            }
                        },
                        tooltip: {
                            bodyFont: {
                                family: 'poppins',
                                weight: '400'
                            },
                            titleFont: {
                                family: 'poppins',
                                weight: '400'
                            }
                        },
                        subtitle: {
                            display: true,
                            text: 'Situation des revenus',
                            font: {
                                family: 'poppins',
                                weight: '700'
                            }
                        }
                    },
                    scales: {
                        y: {
                            ticks: {
                                font: {
                                    family: 'poppins',
                                    weight: '500'
                                }
                            }
                        },
                        x: {
                            ticks: {
                                font: {
                                    family: 'poppins',
                                    weight: '500'
                                }
                            }
                        }
                    }
                }
            }
        );
    }
})

// Functions
function CloseModal() {
    const popUpModal = document.querySelector(".modal__box:not(.excel_export_popUp)")
    popUpModal.classList.toggle("active")
    document.querySelector("body").style.overflow = "auto"
    setTimeout(function () {
        document.querySelector("#JS_GenerateForm").innerHTML = ""
    }, 200)
}

function submitForm(element) {
    element.setAttribute("disabled", "")
    let animation = element.parentElement.parentElement
    let formParent = element.parentElement.parentElement.querySelector(".modal__body")
    let formElement = formParent.querySelector(".modal__body form")
    let payload = new FormData(formElement)
    let allSelect = formElement.querySelectorAll("textarea[is='text-editor']")
    allSelect.forEach((item) => {
        let content = tinymce.get(item.id).getContent()
        payload.set(item.name, content)
    })

    let url = formElement.action
    let formControlResult = formControl(formElement, payload)

    if (formControlResult === true) {
        animation.classList.add("show__loader")
        fetch(url, {
            method: "POST",
            body: payload,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
            .then(res => res.json())
            .then(data => {
                animation.classList.add("show__loader")
                if (data?.target !== null && data?.target === "_blank"){
                    CloseModal()
                    window.open(data?.redirect, '_blank').focus();
                    return 0
                }
                Turbolinks.visit(data?.redirect + window.location.search)
                document.addEventListener("turbolinks:load", function () {
                    if (data !== undefined) {
                        NotificationToast(data.type, data.message)
                        setTimeout(() => { //remove data after 500ms
                            data = undefined;
                        }, 500);
                    }
                })
            })
            .catch(err => {
                element.removeAttribute("disabled")
                animation.classList.remove("show__loader")
                NotificationToast("error", "Erreur XHR: " + err)
            });
        return true
    }
    setTimeout(() => {
        element.removeAttribute("disabled")
    }, 1000)
}

loadInputFile()

function loadInputFile() {

    let allInputUploadImage = document.querySelectorAll(".JS_load__previewImg");
    allInputUploadImage.forEach((item) => {
        let uploadImagePreview = document.querySelector("#load__previewImg_" + item.name)
        let uploadImageDeletePreview = item.parentElement.querySelector(".deleteUploadPreview")
        item.addEventListener("change", () => {
            let type = item.files[0].type === "application/pdf" ? "du document" : "de l'image"
            let maxSize = parseInt(item.dataset.size, 10)
            if (item.files && item.files.length === 1 && item.files[0].size > maxSize) {
                customAlert("La taille " + type + " <b>" + item.files[0].name + "</b> ne doit pas dépasser <b>" + maxSize / 1024 / 1024 + "MB</b>.")
                item.value = null;
                uploadImagePreview.setAttribute('src', uploadImagePreview.dataset.img);
                uploadImageDeletePreview.style.display = "none";
            } else {
                const fileSrc = item.files[0];
                if (fileSrc) {
                    const reader = new FileReader();
                    reader.addEventListener("load", () => {
                        if (item.files[0].type.indexOf("image") !== -1) uploadImagePreview.setAttribute('src', reader.result);
                        uploadImageDeletePreview.style.display = "block";
                    });
                    reader.readAsDataURL(fileSrc);
                } else {
                    // item.value = uploadImagePreview.src;
                    if (item.files[0].type.indexOf("image") !== -1) uploadImagePreview.setAttribute('src', uploadImagePreview.dataset.img);
                    uploadImageDeletePreview.style.display = "none";
                }
            }
        })

        uploadImageDeletePreview.addEventListener("click", (e) => {
            e.preventDefault();
            if (item.value != null) {
                item.value = null;
                uploadImagePreview.setAttribute('src', uploadImagePreview.dataset.img);
                uploadImageDeletePreview.style.display = "none";

                let removeInput = document.querySelector("#remove_" + item.name)
                if (removeInput !== null) {
                    removeInput.value = true
                }
            }
        })
    })
}


function toggleEditForm() {
    let switch_btn = document.querySelector(".modal__box .modal__header #JS_editModal")
    if (switch_btn !== null) {
        verifyToggle(switch_btn)
        switch_btn.addEventListener('click', () => {
            verifyToggle(switch_btn)
        })
    }

    function verifyToggle(switch_btn) {
        if (switch_btn.checked === false) {
            let allInput = document.querySelectorAll(".modal__body input")
            allInput.forEach((item) => {
                item.setAttribute('disabled', '')
            })

            let allSelect = document.querySelectorAll(".modal__body select")
            allSelect.forEach((item) => {
                item.setAttribute('disabled', '')
            })

            let allTextArea = document.querySelectorAll(".modal__body textarea")
            allTextArea.forEach((item) => {
                item.setAttribute('disabled', '')
            })

            let submitBtn = document.querySelectorAll(".modal__footer input")
            submitBtn.forEach((item) => {
                item.style.opacity = 0
                item.style.visibility = "hidden"
            })

            let requiredIcon = document.querySelectorAll(".modal__body [data-control*=noEmpty] + label + i")
            requiredIcon.forEach((item) => {
                item.style.opacity = 0
                item.style.visibility = "hidden"
                item.setAttribute('disabled', '')
            })

            let requiredIcon2 = document.querySelectorAll(".modal__body [data-control*=noEmpty] + .ss-main + label +i")
            requiredIcon2.forEach((item) => {
                item.style.opacity = 0
                item.style.visibility = "hidden"
                item.setAttribute('disabled', '')
            })

            let br = document.querySelector('.modal__body + br')
            if (br !== null) {
                br.style.display = "none"
            }
            let required__placeholder = document.querySelector(".required__placeholder")
            if (required__placeholder !== null) {
                required__placeholder.style.display = "none"
            }
        } else {
            let allInput = document.querySelectorAll(".modal__body input")
            allInput.forEach((item) => {
                item.removeAttribute('disabled')
            })

            let allSelect = document.querySelectorAll(".modal__body select")
            allSelect.forEach((item) => {
                if (item.getAttribute("forceDisabled") == null) {
                    item.removeAttribute('disabled')
                }
            })

            let allTextArea = document.querySelectorAll(".modal__body textarea")
            allTextArea.forEach((item) => {
                item.removeAttribute('disabled')
            })

            let submitBtn = document.querySelectorAll(".modal__footer input")
            submitBtn.forEach((item) => {
                item.style.opacity = 1
                item.style.visibility = "visible"
            })

            let requiredIcon = document.querySelectorAll(".modal__body [data-control*=noEmpty] + label + i")
            requiredIcon.forEach((item) => {
                item.style.opacity = 1
                item.style.visibility = "visible"
                item.removeAttribute('disabled')
            })

            let requiredIcon2 = document.querySelectorAll(".modal__body [data-control*=noEmpty] + .ss-main + label + i")
            requiredIcon2.forEach((item) => {
                item.style.opacity = 1
                item.style.visibility = "visible"
                item.removeAttribute('disabled')
            })
            let br = document.querySelector('.modal__body + br')
            if (br !== null) {
                br.style.display = "block"
            }

            let required__placeholder = document.querySelector(".required__placeholder")
            if (required__placeholder !== null) {
                required__placeholder.style.display = "flex"
            }
        }
    }
}
